
import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest, getClaim } from '../../authConfig';
import TenantService from '../../services/TenantService';

const UserContext = React.createContext({ user: null, tenant: null, tenants: null, setUser: null, setTenant: null, setTenants: null }); // Create a context object

const UserProvider = ({ children }) => {
  const { instance, accounts } = useMsal();
  const account = accounts[0];
  const service = new TenantService();

  const [systems, setSystems] = useState(null);

  const [user, setUser] = useState(null);
  const [tenant, setTenant] = useState(null);
  const [tenants, setTenants] = useState(null);

  useEffect(() => {
    if (account) {
      getAccessToken();
    }
    setUser(null);
    setTenant(null);
    setTenants(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [account])

  useEffect(() => {
    //console.log("User: ", user)
    if (user?.isAdmin && systems) {
      getAllTenantSystems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [user]);

  useEffect(() => {
    //console.log("Tentant: ", tenant);
  }, [tenant]);

  useEffect(() => {
    //console.log("Tenants: ", tenants);
  }, [tenants]);

  const getAccessToken = async () => {
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: account
      });
      const accessToken = response?.accessToken || null;
      //setClientName(getClaim(accessToken)["companyname"]);
        await getTenant(getClaim(accessToken)["companyname"]);
      //console.log(accessToken.getAccessToken())
      return accessToken;
      // Use the access token to call your Web API
    } catch (error) {
      // If acquireTokenSilent fails, you might need to use acquireTokenPopup instead
      //console.error('Failed to acquire access token:', error);
      return null;
    }
  }

  const getTenant = async (tenantId) => {
    const response = await service.getTenantById(tenantId);
    const response2 = await service.getTenantSystems();
    setSystems(response2);
    setUser({
      isAdmin: account?.idTokenClaims?.roles?.includes("web-admin"),
      companyName: response?.companyName || null
    });
    response.path = response.companyName?.toLowerCase()?.replaceAll(/\s/g, '');
    response.appId = response2?.find((a) => response.id === a?.tenantId)?.systems?.find((b) => "TRI" === b.systemCode)?.settings?.DatabaseConnectionString || null
    setTenant(response || null)
  };

  const getAllTenantSystems = async () => {
    const tmp = [];
    systems?.forEach((item) => {
      if (item?.systems?.find((b) => "TRI" === b.systemCode)) {
        tmp.push({
          id: item?.tenantId,
          companyName: item?.companyName,
          path: item?.companyName?.toLowerCase()?.replaceAll(/\s/g, ''),
          appId: item?.systems?.find((b) => "TRI" === b.systemCode)?.settings?.DatabaseConnectionString || null
        })
      }
    });
    setTenants(tmp);
  }

  const getProvider = () => {
    return {
      user: user,
      tenant: tenant,
      tenants: tenants,
      setUser: setUser,
      setTenant: setTenant,
      setTenants: setTenants
    }
  }

  return (
    <UserContext.Provider value={getProvider()}>
      {children}
    </UserContext.Provider>
  );
}

export {
  UserContext, // Export it so it can be used by other Components
  UserProvider
};
